<script lang='ts'>
  import PageInfo from '@/components/ui/PageInfo.svelte'

  interface MyWindow extends Window {
    [key: string]: any;
  }

  import { link } from 'svelte-routing'

  import { WORD_RATING_MARK_AS_KNOWN } from '@/config/constants'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { setToolsLanguages } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'

  import { BurriedWord, VocabularyApiData, VocabularyLegend, VocabularyType, VocabularyWord } from '@/definitions/langoid'
  import { chatSelectedWord, toolsMenuStore, userStorredConfig, vocabularyType } from '@/store'

  export let type: VocabularyType = 'words'
  const iso = getIsoFromId($userStorredConfig.id_lang_learning)

  const selectedLang: number = $userStorredConfig.id_lang_learning
  let languageStats: VocabularyApiData
  let errorMsg = ''
  let levels: string[] = []
  let selectedGroups: string[] = []
  let wordsToShow: VocabularyWord[] = []
  let labels: string[]
  let legend: VocabularyLegend[]
  let loaded = false
  let showBurried = false
  let wordsCount = 0
  let myChart: any
  let burried: BurriedWord[] = []
  const style = getComputedStyle(document.body)

  vocabularyType.subscribe((value: VocabularyType) => {
    type = value
  })

  $: if (languageStats) {
    if (type === 'words') levels = Object.keys(languageStats.levels).filter(el => el !== '' && el !== '--')
    labels = languageStats.labels.map(label => $_('vocabularyPage.label_' + label))
    wordsCount = 0
    legend = languageStats.legend.map(item => {
      wordsCount += item.count || 0
      return {
        ...item,
        name: $_('vocabularyPage.label_' + item.name)
      }
    })
  }
  $: if (wordsCount && myChart) {
    onLoadVocabulary()
  }

  $: if (selectedGroups && languageStats) {
    wordsToShow = []
    for (const i in languageStats.words) {
      if (selectedGroups.includes(languageStats.words[i].word_class)) {
        wordsToShow.push(languageStats.words[i])
      }
    }
    wordsToShow.sort((a, b) => a.word_class.localeCompare(b.word_class))
    burried = languageStats.burried
  }

  changeType(type)

  async function changeType (newType: VocabularyType) {
    type = newType
    const data: VocabularyApiData = await fetchData('learn/vocabulary', { selectedLang, type })
    if (data.error) {
      notifier.error($_(data.error))
      errorMsg = $_(data.error) // todo - not reachable
    } else {
      vocabularyType.set(type)
      languageStats = data
      loaded = true
    }
  }

  async function forgetWord (wordId: number, event: MouseEvent) {
    await fetchData('learn/vocabularyForgot', { wordId })
    const targetEvent = event.target as HTMLElement
    targetEvent.style.visibility = 'hidden'
  }

  function onMnemoInput (e: KeyboardEvent) {
    const targetEvent = e.target as HTMLInputElement
    const wordId = targetEvent.id.split('_')[1]
    fetchData('learn/lessonSaveMnemo', { mnemo: targetEvent.value, wordId })
  }

  async function onLoadVocabulary () {
    const context = myChart.getContext('2d')
    const { Chart } = await import('@/helpers/chart')
    const key: string = 'ChartGlobal'
    const myWindow = window as MyWindow
    if (myWindow[key]) {
      myWindow[key].destroy()
    }
    // eslint-disable-next-line no-new
    myWindow[key] = new Chart(context, {
      data: {
        datasets: [
          {
            backgroundColor: [
              style.getPropertyValue('--seen'),
              style.getPropertyValue('--starting'),
              style.getPropertyValue('--good'),
              style.getPropertyValue('--know')
            ],
            borderWidth: 0,
            data: languageStats.data
          }
        ],
        labels
      },
      type: 'pie'
    })
  }

  async function unburyWord (id: number) {
    await fetchData('learn/vocabularyUnbury', { id, type })
    notifier.success($_('vocabularyPage.wordReturned')) // todo - add to message info that they need to reload the page to see the changes
    burried = burried.filter(el => el.id_word !== id)
  }

  function wordInfo (word: VocabularyWord) {
    if (['words'].includes(type)) {
      const newWord = {
        content: word.word,
        id: word.id_word,
        word: word.word,
        wordId: word.id_word
      }
      toolsMenuStore.update(state => {
        state.toolsPage = 'wordInfo'
        state.addWordsType = 'word'
        state.showTools = true
        return state
      })
      setToolsLanguages(selectedLang)
      chatSelectedWord.set(newWord)
    }
  }
</script>
<Breadcrumb
  breads={[ { text: '', url: `/${iso}` }, { text: $_('vocabularyPage.title')} ]}
>
  <h2 class='heading-mobile'>{$_('vocabularyPage.title')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='vocabulary' />
  </div>
</Breadcrumb>
{#if loaded && !errorMsg}
  <div class='vocabulary-page _gap24 -unwrapMobile'>
    <div class='_horizontal'>
      <select bind:value={type} on:change={() => changeType(type)}>
        <option value='words'>Words</option>
        <option value='sentences'>Sentences</option>
        <option value='audioSentences'>Audio sentences</option>
        <option value='customWords'>Custom words</option>
        <option value='customSentences'>Custom sentences</option>
      </select>
    </div>
    <div class='graph-wrapper'>
      <div class='graph'>
        <canvas bind:this={myChart} height='400' width='400' />
      </div>
      {#if type === 'words'}
        <div class='explore-words'>
          <h2>{$_('vocabularyPage.exploreWords')}</h2>
          {#each legend as item}
            <div class='level-info'>
              <span class='level {item.class}' />
              {#if item.count > 0}
                <a href={`/${iso}/words-explorer/${item.class}`} use:link>{item.name} {item.count}</a>
              {:else}
                <span>{item.name} {item.count}</span>
              {/if}
            </div>
          {/each}
        </div>
      {/if}
    </div>
    <div class='responsive-table'>
      <div class='vocabulary-checkboxes'>
        {#each legend as item}
          <div class='{item.class} checkbox' class:-active={selectedGroups.includes(item.class)}>
            <input id={item.name} type='checkbox' value={item.class} bind:group={selectedGroups} />
            <label for={item.name}>
              <span class='_desktopOnly'>{item.name}</span>
              <span>{item.count}</span>
            </label>
          </div>
        {/each}
      </div>
      <table class='_table -words'>
        {#if wordsToShow.length > 0}
          <tr>
            <th>{$_('vocabularyPage.table_word')}</th>
            <th>{$_('learnPage.addNote')}</th>
          </tr>
          {#each wordsToShow as word (word.id_word)}
            <tr>
              <td on:click={() => wordInfo(word)}>{word.word}</td>
              <td>
                <input
                  id='mnemo_{word.id_word}'
                  type='text'
                  value={word.mnemo}
                  on:keyup={onMnemoInput}
                />
                {#if word.rating === WORD_RATING_MARK_AS_KNOWN}
                  <button type='button' on:click={(event) => forgetWord(word.id_word, event)}>
                    {$_('vocabularyPage.iForgotThis')}
                  </button>
                {/if}
              </td>
            </tr>
          {/each}
        {:else}
          {#if type === 'words'}
            <tr>
              <th />
              <th>{$_('vocabularyPage.table_know')}</th>
              <th>{$_('vocabularyPage.table_complete')}</th>
              <th>{$_('vocabularyPage.table_seen')}</th>
              <th colspan='4'>{$_('vocabularyPage.table_levels')}</th>
            </tr>

            {#each levels as level}
              {@const lvl = languageStats.levels[level]}
              <tr>
                <td class='bold'>{level.toUpperCase()}</td>
                <td>{lvl.sum} / {lvl.all}</td>
                <td>{lvl.complete}%</td>
                <td class='bold'>{lvl.seen}</td>
                <td class='L0'>{lvl.L0}</td>
                <td class='L1'>{lvl.L1}</td>
                <td class='L2'>{lvl.L2}</td>
                <td class='L3'>{lvl.L3}</td>
              </tr>
            {/each}
            <tr>
              <td />
              <td class='bold'>{languageStats.know} / {languageStats.total}</td>
              <td />
              <td class='bold'>{languageStats.all}</td>
              {#each languageStats.data as total}
                <td>{total}</td>
              {/each}
            </tr>
          {:else}
            <tr>
              <th />
              <th colspan='4'>{$_('vocabularyPage.table_levels')}</th>
            </tr>
            <tr>
              <td class='bold'>{languageStats.all}</td>
              {#each languageStats.data as total}
                <td>{total}</td>
              {/each}
            </tr>
          {/if}
        {/if}
      </table>
    </div>
    {#if burried.length > 0}
      <input
        type='button'
        value='Show burried'
        on:click={() => { showBurried = true }}
      />
      <div class='responsive-table' style:display={showBurried ? 'block' : 'none'}>
        <table class='table'>
          <tr>
            <th />
            <th>{$_('vocabularyPage.hidingPeriod')}</th>
            <th>{$_('vocabularyPage.unlockDate')}</th>
            <th />
          </tr>
          {#each burried as word}
            <tr>
              <td>{word.word}</td>
              <td>{word.burried}</td>
              <td>{word.burried === 'forever' ? '∞' : word.time_next.split(' ')[0]}</td>
              <td>
                <button type='button' on:click={() => unburyWord(word.id_word)}>
                  {$_('vocabularyPage.unbury')}
                </button>
              </td>
            </tr>
          {/each}
        </table>
      </div>
    {/if}
  </div>
{:else}
  {#if errorMsg}
    <div class='error'>{errorMsg}</div>
  {:else if loaded}
    <h1 class='title'>{$_('vocabularyPage.title')}</h1>
    <EmptyState>{$_('vocabularyPage.noWords')}</EmptyState>
  {:else}
    <div>{$_('mix.loading')}</div>
  {/if}
{/if}

<style lang='scss'>
  .vocabulary-page {
    > :global(.dropdown-wrapper) {
      align-self: center;
      width: fit-content;
    }
  }

  .graph-wrapper {
    display: flex;
    gap: 2.4rem;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    > .graph {
      > canvas {
        max-width: 100%;
      }
    }
  }

  .explore-words {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .level-info {
    display: flex;
    gap: 0.8rem;
    max-width: 25.6rem;

    > .level {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
    }
  }

  .vocabulary-checkboxes {
    display: flex;
    font-size: 1.2rem;
    color: var(--text-primary-color);

    > .checkbox {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      border-radius: 0.8rem 0.8rem 0 0;
      opacity: 0.6;

      &.-active {
        opacity: 1;
      }

      > label {
        margin: 0;
      }
    }
  }

  .responsive-table {
    > table {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .vocabulary-page {
      > :global(.dropdown-wrapper) {
        width: 100%;
      }
    }

    .graph-wrapper {
      flex-direction: column;
    }
  }
</style>
