<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { defaultFeatures } from '@/helpers/langHelper'

  import LanguagePageLink from '@/components/languagePage/LanguagePageLink.svelte'

  import { LanguageStatus } from '@/definitions/langoid'
  import { languagesStore, userStorredConfig } from '@/store'

  export let iso = '',
    rand = 0

  let languageStatus: LanguageStatus = {
    features: defaultFeatures,
    maxLevel: 'A0',
    status: undefined
  }

  let status = 'new'
  let suggestedLevel = 'A1'
  let isMyNative = false

  const unsubscribe2 = languagesStore.subscribe((languages) => {
    languageStatus = languages[iso]
    if (languageStatus) {
      suggestedLevel = languageStatus.maxLevel || 'A1'
      isMyNative = languageStatus.native || false
      status = languageStatus.status || 'new'
    }
  })
  const unsubscribe5 = userStorredConfig.subscribe(async (config) => {
    if (config.id_lang_learning) {
      iso = getIsoFromId(config.id_lang_learning)
    }
  })

  onDestroy(() => {
    unsubscribe2()
    unsubscribe5()
  })
</script>
<div class='action-block _gap24 -unwrapMobile'>
  <input type='hidden' value={rand} name='rand' />
  {#if languageStatus?.status}
    {#if isMyNative}
      <h3 class='myNativeLanguageText'>My native language</h3>
    {/if}
    {#if status !== 'finished'}
      <p>{@html $_('levelPage.notFullyAddedLanguage')}</p>
    {/if}
    <div class='action-tasks'>
      <!-- 1 -->
      <LanguagePageLink
        icon='Notebook'
        {languageStatus}
        priv='lessons'
        title={$_('levelPage.lessons')}
        url={`/${iso}/lessons`}
      />
      <LanguagePageLink
        icon='Lightning'
        {languageStatus}
        priv='tests'
        title={$_('levelPage.tests')}
        url={`/${iso}/tests`}
      />

      <!-- 2 -->
      <LanguagePageLink
        icon='Headphones'
        {languageStatus}
        priv='listen.words'
        title={$_('levelPage.listen')}
        url={`/${iso}/listen`}
      />
      <LanguagePageLink
        icon='Notepad'
        {languageStatus}
        priv='tasks'
        title={$_('levelPage.exercises')}
        url={`/${iso}/task`}
      />
      <!-- 3 -->
      <LanguagePageLink
        icon='BookOpenText'
        {languageStatus}
        priv='reading'
        title={$_('levelPage.learnToRead')}
        url={`/${iso}/reading-learn`}
      />
      {#if suggestedLevel !== 'none' && suggestedLevel > 'A1'}
        <LanguagePageLink
          icon='Checks'
          {languageStatus}
          priv='qualificationTest'
          title={$_('testsPage.vocabularyChecker')}
          url={`/${iso}/vocabulary-checker`}
        />
      {:else}
        <LanguagePageLink
          icon='Exam'
          {languageStatus}
          priv='qualificationTest'
          title={$_('testsPage.qualification')}
          url={`/${iso}/qualification-tests`}
        />
      {/if}
      <!-- 4 -->
      <LanguagePageLink
        icon='ChatsCircle'
        {languageStatus}
        priv='chat'
        title={$_('mix.chat')}
        url={`/${iso}/chat`}
      />
      <!--
      <LanguagePageLink
        icon='House'
        {languageStatus}
        priv='word.forms'
        title={$_('levelPage.wordForms')}
        url={`/${iso}/words-forms`}
      />
      -->
      <!-- 5 -->
      <LanguagePageLink
        icon='ChartLineUp'
        {languageStatus}
        priv='goals'
        title={$_('levelPage.goals')}
        url={`/${iso}/goals`}
      />
      <!--
      <LanguagePageLink
        icon='Info'
        {languageStatus}
        priv='guides'
        title={$_('levelPage.guides')}
        url={`/${iso}/guides`}
      />
      -->
    </div>

  {:else}
    Loading...
  {/if}
</div>

<style lang='scss'>
  .action-block {
    text-align: center;

    > .myNativeLanguageText {
      margin-top: 3.2rem;
    }
  }

  @media(max-width: 768px) {
    .action-tasks {
      margin: 0;
    }
  }
</style>
