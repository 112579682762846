<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link } from 'svelte-routing'

  import { ALLOW_ROLE_FOR_PREMIUM } from '@/config/constants'

  import { actionBlockAdded } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'

  import { LanguageFeatures, LanguageLinkPriv, LanguageStatus } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  export let priv: LanguageLinkPriv = '',
    url = '',
    icon = '',
    variant = 'normal',
    title = '',
    languageStatus: LanguageStatus

  let hidden = false
  let isPremium = false
  const hasAccessBlocks = actionBlockAdded(priv, (languageStatus?.features || {}) as LanguageFeatures)

  const unsubscribe = userStorredConfig.subscribe(value => {
    isPremium = value.is_admin >= ALLOW_ROLE_FOR_PREMIUM || value.is_premium > 0
  })

  let active = hasAccessBlocks
  $:if (languageStatus) {
    hidden = false
    active = hasAccessBlocks
    if (languageStatus?.status !== 'finished') {
      active = priv === 'tasks' || priv.startsWith('task.')
    }
    if (languageStatus?.native) {
      if (
        ['lessons', 'qualificationTest', 'word.forms', 'reading', 'goals', 'guides'].includes(priv) ||
        priv.startsWith('test') ||
        priv.startsWith('listen')
      ) {
        active = false
        hidden = true
      }
    }

    if (priv === 'goals' || priv === 'guides') {
      if (isPremium && !languageStatus?.native) {
        active = true
      }
    }
  }

  const handleDisabled = (e: Event, active: boolean) => {
    if (!active) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  onDestroy(() => {unsubscribe()})
</script>
{#if !hidden}
  <a
    class={`level-link ${active ? '-active' : '-inactive'} -${variant}`}
    href={url}
    on:click={e => handleDisabled(e, active)}
    use:link
  >
    <Icon {icon} size={64} />
    {#if variant === 'help'}
      <span class='correctTaskTitle'>{$_('mix.correctly')}</span>
    {/if}
    <p>{title}</p>
  </a>
{/if}
<style lang='scss'>
  .level-link {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    padding: 1.6rem;
    font: var(--Medium-400);
    text-align: center;
    color: var(--text-primary-color);
    border: 2px solid var(--Gray-Light);
    border-radius: 1.6rem;

    &.-active {
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: var(--Primary-Medium);
        border: 2px solid var(--Primary-Medium);
        box-shadow: var(--Shadow-X);
      }
    }

    &.-inactive {
      color: var(--Gray-Medium);
      cursor: not-allowed;
      filter: grayscale(1);
    }

    &.-my,
    &.-help {
      position: relative;
      box-shadow: var(--Shadow-Z);

      > .correctTaskTitle {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 0.8rem 6.3rem;
        font-size: 1.6rem;
        color: var(--inverted-text-color);
        background: var(--Primary-Medium);
        border-radius: 2.4rem 2.4rem 0 0;
      }
    }
  }

  @media (max-width: 768px) {
    .level-link {
      gap: 0.8rem;
      padding: 1.6rem 0.8rem;
      font: var(--Semibold-400);

      > :global(svg) {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }
</style>
