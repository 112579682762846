<script lang='ts'>
  import { navigate } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { has, saveActivity } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { checkIsFinished, getEnhancedWords } from '@/helpers/lessonHelpers'
  import { notifier } from '@/helpers/notifier'
  import { removePostSyntax } from '@/helpers/removePostSyntax'

  import Controller from '@/components/lesson/Controller.svelte'
  import EditMnemo from '@/components/lesson/EditMnemo.svelte'
  import ExampleSentencesAccordion from '@/components/lesson/ExampleSentencesAccordion.svelte'
  import FormsTable from '@/components/lesson/FormsTable.svelte'
  import LessonWordBlock from '@/components/lesson/LessonWordBlock.svelte'
  import Sentence from '@/components/lesson/Sentence.svelte'
  import PropsList from '@/components/tools/PropsList.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import Loader from '@/components/ui/Loader.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { ExampleSentences as ExampleSentencesType, LessonPageData, LessonSentence, LessonWord, WordsTagged } from '@/definitions/langoid'

  export let iso = '',
    level = '',
    lessonId = ''

  if (level === 'A0') level = 'A1'
  let lesson: LessonPageData,
    currentWordKey: number,
    translation: string,
    currentWord: LessonWord = {} as LessonWord,
    words: WordsTagged[] = [],
    currentSentenceIndex = 0,
    sentencesKeys: number[] = [],
    currentSentence: LessonSentence = {} as LessonSentence,
    currentWordIndex = 0,
    isFinished = false,
    isLastWord = false,
    exampleSentences: Record<number, ExampleSentencesType[]> = {},
    breads: any = [],
    props: any = {}

  $: if (lesson) {
    if (lesson.words?.length === 0) {
      notifier.error(' This lesson is empty. Please contact us and report problem.', { duration: 30000 })
    } else {
      currentSentence = lesson.sentences[sentencesKeys[currentSentenceIndex]]
      words = getEnhancedWords(currentSentence?.tagged, lesson.words)
      translation = currentSentence?.translation.sentence2
      currentWordKey = getCurrentWordKey()
      currentWord = lesson.words[currentWordKey] || { forms: [] }
      if (has('priv', 'premium')) {
        exampleSentences = lesson.exampleSentences || {}
      }

      const wordLearningStatus = currentWord['learning-status']
      if (wordLearningStatus === 'L') {
        fetchData('learn/lessonAddToLearningLoop', { wordId: currentWord.id })
        words[currentWordIndex].learningStatus = 'L0'
        lesson.words[currentWordKey]['learning-status'] = 'L0'
      }

      isFinished = checkIsFinished(lesson.lessonFinished, sentencesKeys, lesson)
    }
  }

  loadLesson(lessonId)

  async function loadLesson (id: string) {
    isLastWord = false
    const data: LessonPageData = await fetchData('learn/lesson', { lessonId: id })
    if (!data.error) {
      lesson = data
      sentencesKeys = Object.values(lesson.sentences || []).sort((a, b) => a.order_in_lesson - b.order_in_lesson).map(e => e.id)
    } else {
      notifier.warning($_(data.error), { duration: 30000 }) // todo - not reachable
    }
    currentSentenceIndex = 0
    currentWordIndex = 0
    breads = [
      { text: '', url: `/${iso}` },
      { text: $_('levelPage.lessons') + ' ' + level.toUpperCase(), url: `/${iso}/lessons/${level.toLowerCase()}` },
      { text: lesson?.currentLesson?.name || '' }
    ]
    props = data.props
  }

  async function onClickOnFinish () {
    await fetchData('learn/lessonFinish', { lessonId })
    const prevPath = window.location.pathname.split('/')
    prevPath.pop()
    navigate(prevPath.join('/'))
  }

  function onClickOnWord (selected: number) {
    saveActivity(['lesson-word-info', '', words[currentWordIndex].id_word])
    currentWordIndex = selected
  }

  function onSentenceChange (direction: string) {
    if (direction === 'prev') {
      currentSentenceIndex--
    } else if (direction === 'next') {
      currentSentenceIndex++
    } else {
      currentSentenceIndex = parseInt(direction)
    }
    isLastWord = currentSentenceIndex === sentencesKeys.length - 1
    currentWordIndex = 0
  }

  function getCurrentWordKey () {
    if (!words[currentWordIndex]) return 0
    if (words[currentWordIndex].id_word <= 0 && words[currentWordIndex].isWord) {
      currentWordIndex = findNextWordIndex('next')
    }
    return words[currentWordIndex].id_word
  }

  const prevSentence = () => {
    saveActivity(['lesson-next-sentence', 'prev'])
    if (currentSentenceIndex > 0) onSentenceChange('prev')
  }
  const nextSentence = () => {
    saveActivity(['lesson-next-sentence', 'next'])
    if (currentSentenceIndex < sentencesKeys.length - 1) {
      onSentenceChange('next')
    }
  }

  const prevWord = () => {
    saveActivity(['lesson-next-word', 'prev'])
    currentWordIndex = findNextWordIndex('prev')
  }

  const nextWord = () => {
    saveActivity(['lesson-next-word', 'next'])
    if (currentWordIndex < words.length) {
      currentWordIndex = findNextWordIndex('next')
    }
  }

  function findNextWordIndex (direction: string) {
    let index = currentWordIndex
    if (direction === 'next') {
      while (index < words.length - 1) {
        index++
        if (index === words.length - 1 && currentSentenceIndex < sentencesKeys.length - 1) {
          onSentenceChange('next')
        }
        if (!words[index] || !words[index].isWord || words[index].id_word <= 0) {
          continue
        }
        return index
      }
    } else if (direction === 'prev') {
      if (index !== 0) {
        while (index > 0) {
          index--
          if (words[index].isWord && words[index].id_word > 0) return index
        }
      } else if (currentSentenceIndex !== 0) {
        onSentenceChange('prev')
      }
    }
    return currentWordIndex
  }

  function onClickOnProgress (sentenceIndex: string) {
    onSentenceChange(sentenceIndex)
  }

  function handleKeydown (e: KeyboardEvent) {
    const keyPressed = e.key
    const targetEvent = e.target as HTMLElement
    if (targetEvent.classList.contains('mnemo-input')) {
      // we can change line above to make it work like this on any input[type=text]
    } else if (['ArrowLeft', 'ArrowUp', 'ArrowDown', 'ArrowRight'].includes(keyPressed)) {
      e.preventDefault()
      saveActivity(['lesson-shortcut-used', keyPressed])
      if (keyPressed === 'ArrowLeft') {
        prevWord()
      } else if (keyPressed === 'ArrowUp') {
        nextSentence()
      } else if (keyPressed === 'ArrowDown') {
        prevSentence()
      } else if (keyPressed === 'ArrowRight') {
        nextWord()
      }
    }
  }

  function onSave (e: CustomEvent) {
    const mnemo = e.detail.mnemo
    currentWord.mnemo = mnemo
    words[currentWordIndex].mnemo = mnemo
  }
</script>

<svelte:window on:keydown={handleKeydown} />

{#if !lesson || lesson.words?.length === 0}
  <Loader />
{:else}
  <Breadcrumb {breads}>
    <div slot='pageHelperIcons' class='_breadcrumbicons'>
      {#key currentWord.id}
        <EditMnemo idWord={currentWord.id} kebabItem mnemo={currentWord.mnemo || ''} on:mnemo-edited={onSave} />
      {/key}
      <hr class='_mobileOnly' />
      <PageInfo kebabItem pageName='lessonPage' />
      <hr class='_mobileOnly' />
      {#key currentSentence.id}
        <ReportButton category='lesson_sentence' kebabItem rel={`${currentSentence.id}-${currentWord.id}`} />
      {/key}
    </div>
    <div slot='progressBar'>
      <ProgressBar
        maxProgress={sentencesKeys.length}
        {onClickOnProgress}
        progress={currentSentenceIndex + 1}
        showNumbers
        variant='showMobile'
      />
    </div>
  </Breadcrumb>
  <ProgressBar
    maxProgress={sentencesKeys.length}
    {onClickOnProgress}
    progress={currentSentenceIndex + 1}
    showNumbers
    variant='showDesktop'
  />
  <div class='_gap24'>
    <Sentence
      {currentSentence}
      {iso}
      lessonWords={lesson.words}
      {onClickOnWord}
      selected={currentWordIndex}
      {words}
    />
    <div class='lessonWordInfo -sentence'>{removePostSyntax(translation)}</div>
    <hr />
    <LessonWordBlock {currentWord} {iso} props={props[currentWord.id]} />
    <hr class='_desktopOnly' />
    <Controller
      {isFinished}
      {nextSentence}
      {nextWord}
      {onClickOnFinish}
      {prevSentence}
      {prevWord}
    >
      <div slot='nextLesson'>
        {#if isLastWord && lesson.lessonFinished && lesson.next}
          {@const nextLessonId = (lesson.next.id || '').toString()}
          <button
            title={lesson.next.translation}
            type='button'
            on:click={() => { navigate(`/${iso}/${level}/${nextLessonId}`); loadLesson(nextLessonId) }}
          >{$_('learnPage.goToNextLesson')}
          </button>
        {/if}
      </div>
    </Controller>
  </div>
  {#if currentWord.mnemo}
    <Accordion show>
      <div slot='header'>Note</div>
      <div slot='content'>
        {currentWord.mnemo}
      </div>
    </Accordion>
  {/if}
  <FormsTable {currentWord} templates={lesson.templates} />
  <ExampleSentencesAccordion sentences={exampleSentences?.[words?.[currentWordIndex]?.id_word]} />
  <div class='controller-background'>
    <br />
  </div>
{/if}
{#if props[currentWord.id]?.length}
  <Accordion show>
    <div slot='header'>Word props</div>
    <div slot='content'>
      <PropsList wordProps={props[currentWord.id]} />
    </div>
  </Accordion>
{/if}

<style lang='scss'>
  .controller-background {
    display: none;
  }

  @media(max-width: 768px) {
    .controller-background {
      display: block;
      width: 100%;
      height: 4.8rem;
    }
  }
</style>
