<script lang='ts'>
  import { logCommonLocalError, playAudio } from '@/helpers/errorsHelper'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { saveActivity } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { removePostSyntax } from '@/helpers/removePostSyntax'

  import Icon from '@/components/icons/Icon.svelte'
  import ListenAccordion from '@/components/listen/ListenAccordion.svelte'
  import ListenSetupButtons from '@/components/listen/ListenSetupButtons.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { ListeningWords, ListeningWordsApiData, ListenWordsSelectData } from '@/definitions/langoid'

  const silenceAudio = 'https://cdn.langoid.com/silence.mp3'

  export let playType = 'word'
  export let iso = ''
  let returnData: ListenWordsSelectData
  let errorMessage = ''
  let startListening = false

  let direction = 'interface'
  let number = 100
  let speed = 1
  let silenceLength = 1000
  const debug = false // used when testing options

  let type: string[] = []
  const values: Record<string, number> = {}
  let nextDisabled = false
  const selected = (type: string[], values: Record<string, number>): number => {
    const sum = type.reduce((sum, item) => sum + values[item], 0)
    nextDisabled = sum === 0
    return sum
  }
  const DEFAULT_PLAYER_DELAY = 1000

  let words: ListeningWords[] = []
  let current = 0
  let audio: HTMLAudioElement
  let bothPlayed = true
  let isPlaying = true
  let secondOutputBlock: HTMLElement
  let firstOutputBlock: HTMLElement
  const sentence: any = {}

  const pageHeading = playType === 'word' ? $_('listenlistenWords') : $_('listenlistenSentences')

  fetchData('learn/listenWordsSelect', {playType})
    .then((data: ListenWordsSelectData) => {
      data.legend.forEach((item) => {
        values[item.name] = item.count
      })

      data.legend = data.legend.map(item => {
        return {
          ...item,
          translatedName: $_('vocabularyPage.label_' + item.name)
        }
      })
      type = data.legend.filter((el) => el.checked).map((el) => el.name)
      returnData = data
    })
    .catch((error) => {
      errorMessage = error
    })

  async function handleSubmit () {
    startListening = true
    current = 0
    const params = { direction, number, playType, type }
    const data: ListeningWordsApiData = await fetchData('learn/listenWords', params)
    words = data.words.slice(0, number)
    eventPlayed()
    audio.addEventListener('ended', eventPlayed)
    isPlaying = true
  }

  function playNext () {
    ++current
    if (current > words.length - 1) current = 0
    bothPlayed = true
    audio.pause()
    eventPlayed()
  }

  function playPrev () {
    audio.pause()
    --current
    if (current < 0) current = words.length - 1
    bothPlayed = true
    eventPlayed()
  }

  function togglePlayPause () {
    isPlaying = !isPlaying
    if (isPlaying) {
      audio.play()
    } else {
      audio.pause()
    }
  }

  function eventPlayed () {
    let delay: number
    if (direction === 'learning-only') {
      delay = silenceLength
    } else {
      delay = bothPlayed ? DEFAULT_PLAYER_DELAY : silenceLength
    }
    if (isPlaying) {
      setTimeout(() => {
        if (direction === 'learning-only') {
          bothPlayed = true
          loadWord(current, 'learning')
        } else {
          if (!bothPlayed) {
            loadWord(current, direction)
            bothPlayed = true
          } else {
            const newMode = direction === 'learning' ? 'interface' : 'learning'
            loadWord(current, newMode)
            bothPlayed = false
          }
        }
        if (bothPlayed) {
          current = current + 1
          if (current > words.length - 1) {
            current = 0
          }
        }
      }, delay)
    }
  }

  function displayOutputPlaceholders (variant: string) {
    if (secondOutputBlock && firstOutputBlock) {
      if (direction === 'interface') {
        firstOutputBlock.style.visibility = variant === 'learning' ? 'hidden' : 'visible'
      } else {
        secondOutputBlock.style.visibility = variant === 'interface' ? 'hidden' : 'visible'
      }
    }
  }

  const loadVariant = (props: any) => {
    const {
      variant,
      originalAudio,
      translationAudio,
      original,
      translation
    } = props
    if (secondOutputBlock && firstOutputBlock) {
      displayOutputPlaceholders(variant)
      let audioSrc
      if (direction === 'learning-only') audioSrc = originalAudio
      else {
        if (variant === 'interface') audioSrc = originalAudio
        if (variant === 'learning') audioSrc = translationAudio
      }

      if (!audioSrc) {
        logCommonLocalError('CONTENT: Missing audio for listen words ' + JSON.stringify(props))
      }

      audio.src = audioSrc || silenceAudio
      if (direction === 'learning-only') {
        audio.playbackRate = speed
      } else {
        audio.playbackRate = (variant === 'learning' && speed < 1) ? 1 : speed
      }

      try {
        playAudio(audio, variant)
      } catch (error) {
        console.error('listen words', error)
      }
      saveActivity([variant === 'word' ? 'listen-word' : 'listen-sentence'])
      firstOutputBlock.innerHTML = original
      secondOutputBlock.innerHTML = translation
    }
  }

  // need to play both variants and after it's finished
  function loadWord (index: number, variant: string) {
    const word = words[index]
    const originalAudio = word.audio
    const wordTranslation: any = word.translations ? word.translations[0] : { definition: '', example: '', word: '' }
    if(playType === 'sentence') {
      wordTranslation.word = removePostSyntax(wordTranslation.word)
    }
    const translationAudio = wordTranslation.audio
    const translation = `<p class='wordOriginal' style='margin: 0'>
        ${wordTranslation.word}</p>${wordTranslation.definition ? `<p class='text1'>${wordTranslation.definition}</p>` : ''}`
    if(playType === 'sentence') {
      word.word = removePostSyntax(word.word)
    }
    const original = `<p class='wordTranslation' style='margin: 0'>
        ${word.word}</p>${word.definition ? `<p class='text1'>${word.definition}</p>` : ''}`

    loadVariant({
      original,
      originalAudio,
      translation,
      translationAudio,
      variant
    })
  }

  function failedAudio () {
    if (!audio) return
    audio.src = silenceAudio
    audio.load()
    audio.playbackRate = speed
    playAudio(audio, 'silence')
  }

  function closeSecondPageView (e: any) {
    startListening = e.details
  }
</script>
{#if errorMessage}
  {errorMessage}
{:else if !returnData}
  <p>{$_('mix.loading')}</p>
{:else if !startListening}
  <Breadcrumb
    breads={[
    {mobileUrl:`/${iso}/listen`,text:'', url:`/${iso}`},
    {text:pageHeading}
    ]}
  >
    <h2 class='heading-mobile'>{pageHeading}</h2>
    <div slot='pageHelperIcons'>
      <PageInfo kebabItem pageName='listenSetup' />
    </div>
  </Breadcrumb>
  <form class='setup-form _vertical-small' action='' method='post' on:submit|preventDefault={handleSubmit}>
    <div class='select-familiarity _gap24'>
      <div class='title-wrapper'>
        <h2 class='headingDesktop'>{pageHeading}</h2>
        <p class='information'>{$_('listenprepare')}</p>
      </div>
      <hr />
      <ListenSetupButtons {returnData} bind:type />
      <hr />
      <div class='confirmation-wrapper'>
        <div class='words-count'>
          <span>{selected(type, values)}/{returnData.wordsCount}&nbsp;</span>
          <span>{$_('listenwordsSelected')}</span>
        </div>
        <input
          disabled={nextDisabled}
          type='submit'
          value={$_('listenstartListening')}
        />
      </div>
    </div>
    <ListenAccordion bind:number bind:silenceLength bind:speed bind:direction />
  </form>
{:else}
  <Breadcrumb
    breads={[
    {mobileUrl:`/${iso}/listen`,text:'', url:`/${iso}`},
    {text:playType === 'word'?$_('levelPage.listenWords'):$_('levelPage.listenSentences')}
    ]}
    closeLeft
    bind:secondPageView={startListening}
    on:closePageView={closeSecondPageView}
  >
    <div slot='progressBar'>
      <ProgressBar
        clickable={false}
        maxProgress={words.length}
        progress={bothPlayed ? current : current + 1}
        showNumbers
        variant='showMobile'
      />
    </div>
    <div slot='pageHelperIcons' class='_breadcrumbicons'>
      <PageInfo kebabItem pageName='listenPage' />
      {#key sentence.id}
        <hr class='_mobileOnly' />
        <ReportButton category='listen' kebabItem rel={sentence.id} />
      {/key}
    </div>
  </Breadcrumb>
  <div class='progress-bar'>
    <ProgressBar
      clickable={false}
      maxProgress={words.length}
      progress={bothPlayed ? current : current + 1}
      showNumbers
      variant='showDesktop'
    />
  </div>
  <div class='listen-wrapper _gap24'>
    <div class='words-wrapper'>
      {#if direction === 'interface'}
        <div bind:this={secondOutputBlock} class='second' />
        <hr />
        <div bind:this={firstOutputBlock} class='first' />
      {:else}
        <div bind:this={firstOutputBlock} class='first' />
        <hr />
        <div bind:this={secondOutputBlock} class='second' />
      {/if}
    </div>
    <hr />
    <div class='player-buttons'>
      <audio
        bind:this={audio}
        class='mainAudio'
        src={silenceAudio}
        on:error={failedAudio}
      />
      <button
        class='_icon -bigBorder'
        title={$_('listenprev')}
        type='button'
        on:click={playPrev}
      >
        <Icon icon='CaretLeft' weight='regular' />
      </button>
      <button
        class='_filledicon -middle'
        type='button'
        on:click={togglePlayPause}
      >
        <Icon icon={isPlaying ? 'Stop' : 'Play'} weight='fill' />
      </button>
      <button
        class='_icon -bigBorder'
        title={$_('listennext')}
        type='button'
        on:click={playNext}
      >
        <Icon icon='CaretRight' weight='regular' />
      </button>
    </div>
    {#if debug}
      <div class='debug-info'>
        <p>Direction: {direction}</p>
        <p>Number: {number}</p>
        <p>Speed: {speed}</p>
        <p>Variant: {playType}</p>
        <p>Silence length: {silenceLength}</p>
        <p>Current: {current}</p>
        <p>Both played: {bothPlayed}</p>
        <p>Is playing: {isPlaying}</p>
      </div>
    {/if}
  </div>
{/if}

<style lang='scss'>
  .setup-form {
    width: 100%;
    margin: 0;
  }

  .select-familiarity {
    width: 100%;
  }

  .words-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    > .first, > .second {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      width: 100%;
      min-height: 9.6rem;
    }
  }

  :global(.wordOriginal) {
    font: var(--Semibold-800);
    color: var(--Primary-Medium);
  }

  :global(.wordTranslation) {
    font: var(--Semibold-700);
    color: var(--text-secondary-color);
  }

  .title-wrapper {
    > .information {
      margin-top: 0.8rem;
      font: var(--Regular-400);
    }
  }

  .confirmation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .words-count {
    display: flex;
    height: 4rem;
    padding: 1.2rem 0;
    font: var(--Regular-200);
  }

  .player-buttons {
    display: flex;
    justify-content: space-between;

    > .mainAudio {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .progress-bar {
      display: none;
    }

    .heading-mobile {
      padding: 0 0.4rem;
      white-space: nowrap;
    }

    .title-wrapper {
      > .headingDesktop {
        display: none;
      }

      > .information {
        margin-top: 0;
        font: var(--Regular-400);
      }
    }

    .listen-wrapper {
      gap: 0;
      padding: 0;
      background-color: inherit;
      border: none;
      border-radius: 0;

      > hr {
        display: none;
      }
    }

    .select-familiarity {
      padding: 1.6rem;
      border-radius: 1.6rem;
    }

    .words-wrapper {
      padding: 1.6rem;
      background-color: var(--Base-White);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 1.6rem;
    }

    .player-buttons {
      position: fixed;
      bottom: 5rem;
      left: 0;
      width: 100%;
      padding: 0.8rem;
      background-color: var(--Base-White);
      box-shadow: var(--Shadow-L);
    }

    .words-count {
      flex-direction: column;
      padding: 0;
      font: var(--Regular-300);
    }

    ._filledicon {
      padding: 1.2rem 0;
    }
  }
</style>
