<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { sortLanguages } from '@/helpers/chatHelpers'
  import { timeAgo } from '@/helpers/dateHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { initializeAndUpdateDb } from '@/helpers/indexedDbHelpers'

  import ChatFilters from '@/components/chat/ChatFilters.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { ChatFriendsData, UserLanguage } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { _ } from '@/libs/i18n'
  import { menuLanguage, mixStore, userStorredConfig } from '@/store'

  export let iso: string

  let newMessages: MixedStoreData['messagesCounts'] = {},
    chatList: any[] = [],
    search = '',
    showFilters = false
  const chatPage = 'chatHome'
  const breads = [
    { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
    { text: 'menu.messages' }
  ]

  onMount(() => {
    userStorredConfig.setKey('id_lang_learning', getIdFromIso(iso))
    menuLanguage.set(iso)
    getChatList()
    initializeAndUpdateDb()
  })

  const getChatList = async () => {
    await fetchData('chat/loadFriends', { search }).then((data: ChatFriendsData) => {
      const userIds = Object.keys(data.users)
      const my = data.myLanguages.map((el: any) => el.iso)
      chatList = userIds.map((id: any) => {
        const languages = sortLanguages(data.users[id].map((el: any) => {
          return { id: el.id_language, iso: el.iso } as UserLanguage
        }), my, iso)
        return {
          ...data.users[id].find((el2: any) => el2.iso === languages[0].iso),
          languages
        }
      })
    })
  }

  $: if (search && search.length > 1 || !search) {
    getChatList()
  }

  const unsubscribe = mixStore.subscribe((data: MixedStoreData) => {
    newMessages = data.messagesCounts
  })
  onDestroy(() => {unsubscribe()})
</script>
<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('menu.messages')}</h2>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    <FakeButton onClick={() => {showFilters = !showFilters}}>
      <span class='_icon -noBorder' class:-active={showFilters}><Icon icon='Sliders' weight='regular' /></span>
      <span class='filterHeading _mobileOnly'>{$_('forum.filters')}</span>
    </FakeButton>
    <hr class='_mobileOnly' />
    <PageInfo kebabItem pageName='chat' />
  </div>
</Breadcrumb>
<div class='chat-list'>
  <ChatFilters active='' {chatPage} {iso} bind:search bind:showFilters />
  <div class='chat-list-users _gap24'>
    {#each chatList as item}
      <div
        class='chat-item'
        role='button'
        tabindex='0'
        on:click={() => navigate(`/${iso}/chat/${item.id}`)}
        on:keypress={() => {}}
      >
        <UserAvatar avatarVariant='menuAvatar' nameVariant='noName' user={item} />
        <div class='name-and-message'>
          <span class='name'>
            {item.nick || item.name}
            <span class='languages'>
              {#each (item.languages || []) as lang}
                {#key lang.iso}
                  <FakeButton
                    onClick={() => {navigate(`/${lang.iso}/chat/${item.id}`)}}
                  >
                    <Flag iso={lang.iso} />
                    <div class='lang-badge'>{newMessages[item.id]?.[lang.id_language || lang.id] || ''}</div>
                  </FakeButton>
                {/key}
              {/each}
            </span>
          </span>
          <div class='message-wrapper'>
            <span class='message'>{item.message}</span>
            <div class='meta'>
              {#if newMessages[item.id]}
                <span class='_badge'>{Object.values(newMessages[item.id]).reduce((a, b) => a + b, 0) || ''}</span>{/if}
              <div>{item.created_at ? timeAgo(item.created_at) : ''}</div>
            </div>
          </div>
        </div>

      </div>
    {:else}
      <div class='item'>No messages found, go to users tab to search for users.</div>
    {/each}
  </div>
</div>

<style lang='scss'>
  ._icon {
    &.-active {
      background-color: var(--Gray-Medium);
    }
  }

  .chat-list {
    display: flex;
    flex-direction: column;
  }

  .chat-list-users {
    gap: 0;
    border-top: none;
    border-radius: 0 0 2.4rem 2.4rem;
    padding-block: 0.8rem;
  }

  .chat-item {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    padding: 1.2rem 0.8rem;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .name-and-message {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;

    > .name {
      display: flex;
      gap: 1.2rem;
      font: var(--Semibold-400);

      > .languages {
        display: flex;
        gap: 0.4rem;
      }
    }
  }

  .message-wrapper {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-between;

    > .message {
      font: var(--Regular-200);
      color: var(--text-primary-color);
    }

    > .meta {
      display: flex;
      flex-direction: column;
      font: var(--Regular-100);
      color: var(--text-primary-color);
    }
  }

  .lang-badge {
    position: relative;
    top: 1.2rem;
    left: -1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    padding: 0 0.2rem;
    font: var(--Semibold-100);
    line-height: 1;
    text-align: center;
    color: var(--Warning-Medium);
    background: var(--Warning-Lighter);
    border-radius: 1rem;

    &:empty {
      display: none;
    }
  }
</style>
